/* eslint quotes: 0, comma-dangle: 0, quote-props: 0 */
// https://www.iso.org/obp/ui/#search/code/
// altName from http://www.nationsonline.org/oneworld/country_code_list.htm
// shortName from https://countrycode.org
export default [
  {
    name: 'Afghanistan',
    alpha2: 'AF',
  },
  {
    name: 'Åland Islands',
    alpha2: 'AX',
    altName: 'Aland Islands',
  },
  {
    name: 'Albania',
    alpha2: 'AL',
  },
  {
    name: 'Algeria',
    alpha2: 'DZ',
  },
  {
    name: 'American Samoa',
    alpha2: 'AS',
  },
  {
    name: 'Andorra',
    alpha2: 'AD',
  },
  {
    name: 'Angola',
    alpha2: 'AO',
  },
  {
    name: 'Anguilla',
    alpha2: 'AI',
  },
  {
    name: 'Antarctica',
    alpha2: 'AQ',
  },
  {
    name: 'Antigua and Barbuda',
    alpha2: 'AG',
  },
  {
    name: 'Argentina',
    alpha2: 'AR',
  },
  {
    name: 'Armenia',
    alpha2: 'AM',
  },
  {
    name: 'Aruba',
    alpha2: 'AW',
  },
  {
    name: 'Australia',
    alpha2: 'AU',
  },
  {
    name: 'Austria',
    alpha2: 'AT',
  },
  {
    name: 'Azerbaijan',
    alpha2: 'AZ',
  },
  {
    name: 'Bahamas (the)',
    alpha2: 'BS',
    altName: 'Bahamas',
  },
  {
    name: 'Bahrain',
    alpha2: 'BH',
  },
  {
    name: 'Bangladesh',
    alpha2: 'BD',
  },
  {
    name: 'Barbados',
    alpha2: 'BB',
  },
  {
    name: 'Belarus',
    alpha2: 'BY',
  },
  {
    name: 'Belgium',
    alpha2: 'BE',
  },
  {
    name: 'Belize',
    alpha2: 'BZ',
  },
  {
    name: 'Benin',
    alpha2: 'BJ',
  },
  {
    name: 'Bermuda',
    alpha2: 'BM',
  },
  {
    name: 'Bhutan',
    alpha2: 'BT',
  },
  {
    name: 'Bolivia (Plurinational State of)',
    alpha2: 'BO',
    altName: 'Bolivia',
  },
  {
    name: 'Bonaire, Sint Eustatius and Saba',
    alpha2: 'BQ',
  },
  {
    name: 'Bosnia and Herzegovina',
    alpha2: 'BA',
  },
  {
    name: 'Botswana',
    alpha2: 'BW',
  },
  {
    name: 'Bouvet Island',
    alpha2: 'BV',
  },
  {
    name: 'Brazil',
    alpha2: 'BR',
  },
  {
    name: 'British Indian Ocean Territory (the)',
    alpha2: 'IO',
    altName: 'British Indian Ocean Territory',
  },
  {
    name: 'Brunei Darussalam',
    alpha2: 'BN',
    shortName: 'Brunei',
  },
  {
    name: 'Bulgaria',
    alpha2: 'BG',
  },
  {
    name: 'Burkina Faso',
    alpha2: 'BF',
  },
  {
    name: 'Burundi',
    alpha2: 'BI',
  },
  {
    name: 'Cabo Verde',
    alpha2: 'CV',
    altName: 'Cape Verde',
  },
  {
    name: 'Cambodia',
    alpha2: 'KH',
  },
  {
    name: 'Cameroon',
    alpha2: 'CM',
  },
  {
    name: 'Canada',
    alpha2: 'CA',
  },
  {
    name: 'Cayman Islands (the)',
    alpha2: 'KY',
    altName: 'Cayman Islands',
  },
  {
    name: 'Central African Republic (the)',
    alpha2: 'CF',
    altName: 'Central African Republic',
  },
  {
    name: 'Chad',
    alpha2: 'TD',
  },
  {
    name: 'Chile',
    alpha2: 'CL',
  },
  {
    name: 'China',
    alpha2: 'CN',
  },
  {
    name: 'Christmas Island',
    alpha2: 'CX',
  },
  {
    name: 'Cocos (Keeling) Islands (the)',
    alpha2: 'CC',
    altName: 'Cocos (Keeling) Islands',
    shortName: 'Cocos Islands',
  },
  {
    name: 'Colombia',
    alpha2: 'CO',
  },
  {
    name: 'Comoros (the)',
    alpha2: 'KM',
    altName: 'Comoros',
  },
  {
    name: 'Congo (the Democratic Republic of the)',
    alpha2: 'CD',
    altName: 'Congo, (Kinshasa)',
    shortName: 'Democratic Republic of the Congo',
  },
  {
    name: 'Congo (the)',
    alpha2: 'CG',
    altName: 'Congo (Brazzaville)',
    shortName: 'Republic of the Congo',
  },
  {
    name: 'Cook Islands (the)',
    alpha2: 'CK',
    altName: 'Cook Islands',
  },
  {
    name: 'Costa Rica',
    alpha2: 'CR',
  },
  {
    name: "Côte d'Ivoire",
    alpha2: 'CI',
    shortName: 'Ivory Coast',
  },
  {
    name: 'Croatia',
    alpha2: 'HR',
  },
  {
    name: 'Cuba',
    alpha2: 'CU',
  },
  {
    name: 'Curaçao',
    alpha2: 'CW',
    shortName: 'Curacao',
  },
  {
    name: 'Cyprus',
    alpha2: 'CY',
  },
  {
    name: 'Czechia',
    alpha2: 'CZ',
    altName: 'Czech Republic',
  },
  {
    name: 'Denmark',
    alpha2: 'DK',
  },
  {
    name: 'Djibouti',
    alpha2: 'DJ',
  },
  {
    name: 'Dominica',
    alpha2: 'DM',
  },
  {
    name: 'Dominican Republic (the)',
    alpha2: 'DO',
    altName: 'Dominican Republic',
  },
  {
    name: 'Ecuador',
    alpha2: 'EC',
  },
  {
    name: 'Egypt',
    alpha2: 'EG',
  },
  {
    name: 'El Salvador',
    alpha2: 'SV',
  },
  {
    name: 'Equatorial Guinea',
    alpha2: 'GQ',
  },
  {
    name: 'Eritrea',
    alpha2: 'ER',
  },
  {
    name: 'Estonia',
    alpha2: 'EE',
  },
  {
    name: 'Ethiopia',
    alpha2: 'ET',
  },
  {
    name: 'Falkland Islands (the) [Malvinas]',
    alpha2: 'FK',
    altName: 'Falkland Islands (Malvinas)',
    shortName: 'Falkland Islands',
  },
  {
    name: 'Faroe Islands (the)',
    alpha2: 'FO',
    altName: 'Faroe Islands',
  },
  {
    name: 'Fiji',
    alpha2: 'FJ',
  },
  {
    name: 'Finland',
    alpha2: 'FI',
  },
  {
    name: 'France',
    alpha2: 'FR',
  },
  {
    name: 'French Guiana',
    alpha2: 'GF',
  },
  {
    name: 'French Polynesia',
    alpha2: 'PF',
  },
  {
    name: 'French Southern Territories (the)',
    alpha2: 'TF',
    altName: 'French Southern Territories',
  },
  {
    name: 'Gabon',
    alpha2: 'GA',
  },
  {
    name: 'Gambia (the)',
    alpha2: 'GM',
    altName: 'Gambia',
  },
  {
    name: 'Georgia',
    alpha2: 'GE',
  },
  {
    name: 'Germany',
    alpha2: 'DE',
  },
  {
    name: 'Ghana',
    alpha2: 'GH',
  },
  {
    name: 'Gibraltar',
    alpha2: 'GI',
  },
  {
    name: 'Greece',
    alpha2: 'GR',
  },
  {
    name: 'Greenland',
    alpha2: 'GL',
  },
  {
    name: 'Grenada',
    alpha2: 'GD',
  },
  {
    name: 'Guadeloupe',
    alpha2: 'GP',
  },
  {
    name: 'Guam',
    alpha2: 'GU',
  },
  {
    name: 'Guatemala',
    alpha2: 'GT',
  },
  {
    name: 'Guernsey',
    alpha2: 'GG',
  },
  {
    name: 'Guinea',
    alpha2: 'GN',
  },
  {
    name: 'Guinea-Bissau',
    alpha2: 'GW',
  },
  {
    name: 'Guyana',
    alpha2: 'GY',
  },
  {
    name: 'Haiti',
    alpha2: 'HT',
  },
  {
    name: 'Heard Island and McDonald Islands',
    alpha2: 'HM',
    altName: 'Heard and Mcdonald Islands',
  },
  {
    name: 'Holy See (the)',
    alpha2: 'VA',
    altName: 'Holy See (Vatican City State)',
    shortName: 'Vatican',
  },
  {
    name: 'Honduras',
    alpha2: 'HN',
  },
  {
    name: 'Hong Kong',
    alpha2: 'HK',
    altName: 'Hong Kong, SAR China',
  },
  {
    name: 'Hungary',
    alpha2: 'HU',
  },
  {
    name: 'Iceland',
    alpha2: 'IS',
  },
  {
    name: 'India',
    alpha2: 'IN',
  },
  {
    name: 'Indonesia',
    alpha2: 'ID',
  },
  {
    name: 'Iran (Islamic Republic of)',
    alpha2: 'IR',
    altName: 'Iran, Islamic Republic of',
    shortName: 'Iran',
  },
  {
    name: 'Iraq',
    alpha2: 'IQ',
  },
  {
    name: 'Ireland',
    alpha2: 'IE',
  },
  {
    name: 'Isle of Man',
    alpha2: 'IM',
  },
  {
    name: 'Israel',
    alpha2: 'IL',
  },
  {
    name: 'Italy',
    alpha2: 'IT',
  },
  {
    name: 'Jamaica',
    alpha2: 'JM',
  },
  {
    name: 'Japan',
    alpha2: 'JP',
  },
  {
    name: 'Jersey',
    alpha2: 'JE',
  },
  {
    name: 'Jordan',
    alpha2: 'JO',
  },
  {
    name: 'Kazakhstan',
    alpha2: 'KZ',
  },
  {
    name: 'Kenya',
    alpha2: 'KE',
  },
  {
    name: 'Kiribati',
    alpha2: 'KI',
  },
  {
    name: "Korea (the Democratic People's Republic of)",
    alpha2: 'KP',
    altName: 'Korea (North)',
    shortName: 'North Korea',
  },
  {
    name: 'Korea (the Republic of)',
    alpha2: 'KR',
    altName: 'Korea (South)',
    shortName: 'South Korea',
  },
  {
    name: 'Kuwait',
    alpha2: 'KW',
  },
  {
    name: 'Kyrgyzstan',
    alpha2: 'KG',
  },
  {
    name: "Lao People's Democratic Republic (the)",
    alpha2: 'LA',
    altName: 'Lao PDR',
    shortName: 'Laos',
  },
  {
    name: 'Latvia',
    alpha2: 'LV',
  },
  {
    name: 'Lebanon',
    alpha2: 'LB',
  },
  {
    name: 'Lesotho',
    alpha2: 'LS',
  },
  {
    name: 'Liberia',
    alpha2: 'LR',
  },
  {
    name: 'Libya',
    alpha2: 'LY',
  },
  {
    name: 'Liechtenstein',
    alpha2: 'LI',
  },
  {
    name: 'Lithuania',
    alpha2: 'LT',
  },
  {
    name: 'Luxembourg',
    alpha2: 'LU',
  },
  {
    name: 'Macao',
    alpha2: 'MO',
    altName: 'Macao, SAR China',
    shortName: 'Macau',
  },
  {
    name: 'Macedonia (the former Yugoslav Republic of)',
    alpha2: 'MK',
    altName: 'Macedonia, Republic of',
    shortName: 'Macedonia',
  },
  {
    name: 'Madagascar',
    alpha2: 'MG',
  },
  {
    name: 'Malawi',
    alpha2: 'MW',
  },
  {
    name: 'Malaysia',
    alpha2: 'MY',
  },
  {
    name: 'Maldives',
    alpha2: 'MV',
  },
  {
    name: 'Mali',
    alpha2: 'ML',
  },
  {
    name: 'Malta',
    alpha2: 'MT',
  },
  {
    name: 'Marshall Islands (the)',
    alpha2: 'MH',
    altName: 'Marshall Islands',
  },
  {
    name: 'Martinique',
    alpha2: 'MQ',
  },
  {
    name: 'Mauritania',
    alpha2: 'MR',
  },
  {
    name: 'Mauritius',
    alpha2: 'MU',
  },
  {
    name: 'Mayotte',
    alpha2: 'YT',
  },
  {
    name: 'Mexico',
    alpha2: 'MX',
  },
  {
    name: 'Micronesia (Federated States of)',
    alpha2: 'FM',
    altName: 'Micronesia, Federated States of',
    shortName: 'Micronesia',
  },
  {
    name: 'Moldova (the Republic of)',
    alpha2: 'MD',
    altName: 'Moldova',
  },
  {
    name: 'Monaco',
    alpha2: 'MC',
  },
  {
    name: 'Mongolia',
    alpha2: 'MN',
  },
  {
    name: 'Montenegro',
    alpha2: 'ME',
  },
  {
    name: 'Montserrat',
    alpha2: 'MS',
  },
  {
    name: 'Morocco',
    alpha2: 'MA',
  },
  {
    name: 'Mozambique',
    alpha2: 'MZ',
  },
  {
    name: 'Myanmar',
    alpha2: 'MM',
  },
  {
    name: 'Namibia',
    alpha2: 'NA',
  },
  {
    name: 'Nauru',
    alpha2: 'NR',
  },
  {
    name: 'Nepal',
    alpha2: 'NP',
  },
  {
    name: 'Netherlands (the)',
    alpha2: 'NL',
    altName: 'Netherlands',
  },
  {
    name: 'New Caledonia',
    alpha2: 'NC',
  },
  {
    name: 'New Zealand',
    alpha2: 'NZ',
  },
  {
    name: 'Nicaragua',
    alpha2: 'NI',
  },
  {
    name: 'Niger (the)',
    alpha2: 'NE',
    altName: 'Niger',
  },
  {
    name: 'Nigeria',
    alpha2: 'NG',
  },
  {
    name: 'Niue',
    alpha2: 'NU',
  },
  {
    name: 'Norfolk Island',
    alpha2: 'NF',
  },
  {
    name: 'Northern Mariana Islands (the)',
    alpha2: 'MP',
    altName: 'Northern Mariana Islands',
  },
  {
    name: 'Norway',
    alpha2: 'NO',
  },
  {
    name: 'Oman',
    alpha2: 'OM',
  },
  {
    name: 'Pakistan',
    alpha2: 'PK',
  },
  {
    name: 'Palau',
    alpha2: 'PW',
  },
  {
    name: 'Palestine, State of',
    alpha2: 'PS',
    altName: 'Palestinian Territory',
    shortName: 'Palestine',
  },
  {
    name: 'Panama',
    alpha2: 'PA',
  },
  {
    name: 'Papua New Guinea',
    alpha2: 'PG',
  },
  {
    name: 'Paraguay',
    alpha2: 'PY',
  },
  {
    name: 'Peru',
    alpha2: 'PE',
  },
  {
    name: 'Philippines (the)',
    alpha2: 'PH',
    altName: 'Philippines',
  },
  {
    name: 'Pitcairn',
    alpha2: 'PN',
  },
  {
    name: 'Poland',
    alpha2: 'PL',
  },
  {
    name: 'Portugal',
    alpha2: 'PT',
  },
  {
    name: 'Puerto Rico',
    alpha2: 'PR',
  },
  {
    name: 'Qatar',
    alpha2: 'QA',
  },
  {
    name: 'Réunion',
    alpha2: 'RE',
    shortName: 'Reunion',
  },
  {
    name: 'Romania',
    alpha2: 'RO',
  },
  {
    name: 'Russian Federation (the)',
    alpha2: 'RU',
    altName: 'Russian Federation',
    shortName: 'Russia',
  },
  {
    name: 'Rwanda',
    alpha2: 'RW',
  },
  {
    name: 'Saint Barthélemy',
    alpha2: 'BL',
    altName: 'Saint-Barthélemy',
    shortName: 'Saint Barthelemy',
  },
  {
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    alpha2: 'SH',
    altName: 'Saint Helena',
  },
  {
    name: 'Saint Kitts and Nevis',
    alpha2: 'KN',
  },
  {
    name: 'Saint Lucia',
    alpha2: 'LC',
  },
  {
    name: 'Saint Martin (French part)',
    alpha2: 'MF',
    altName: 'Saint-Martin (French part)',
    shortName: 'Saint Martin',
  },
  {
    name: 'Saint Pierre and Miquelon',
    alpha2: 'PM',
  },
  {
    name: 'Saint Vincent and the Grenadines',
    alpha2: 'VC',
    altName: 'Saint Vincent and Grenadines',
  },
  {
    name: 'Samoa',
    alpha2: 'WS',
  },
  {
    name: 'San Marino',
    alpha2: 'SM',
  },
  {
    name: 'Sao Tome and Principe',
    alpha2: 'ST',
  },
  {
    name: 'Saudi Arabia',
    alpha2: 'SA',
  },
  {
    name: 'Senegal',
    alpha2: 'SN',
  },
  {
    name: 'Serbia',
    alpha2: 'RS',
  },
  {
    name: 'Seychelles',
    alpha2: 'SC',
  },
  {
    name: 'Sierra Leone',
    alpha2: 'SL',
  },
  {
    name: 'Singapore',
    alpha2: 'SG',
  },
  {
    name: 'Sint Maarten (Dutch part)',
    alpha2: 'SX',
    shortName: 'Sint Maarten',
  },
  {
    name: 'Slovakia',
    alpha2: 'SK',
  },
  {
    name: 'Slovenia',
    alpha2: 'SI',
  },
  {
    name: 'Solomon Islands',
    alpha2: 'SB',
  },
  {
    name: 'Somalia',
    alpha2: 'SO',
  },
  {
    name: 'South Africa',
    alpha2: 'ZA',
  },
  {
    name: 'South Georgia and the South Sandwich Islands',
    alpha2: 'GS',
  },
  {
    name: 'South Sudan',
    alpha2: 'SS',
  },
  {
    name: 'Spain',
    alpha2: 'ES',
  },
  {
    name: 'Sri Lanka',
    alpha2: 'LK',
  },
  {
    name: 'Sudan (the)',
    alpha2: 'SD',
    altName: 'Sudan',
  },
  {
    name: 'Suriname',
    alpha2: 'SR',
  },
  {
    name: 'Svalbard and Jan Mayen',
    alpha2: 'SJ',
    altName: 'Svalbard and Jan Mayen Islands',
  },
  {
    name: 'Swaziland',
    alpha2: 'SZ',
  },
  {
    name: 'Sweden',
    alpha2: 'SE',
  },
  {
    name: 'Switzerland',
    alpha2: 'CH',
  },
  {
    name: 'Syrian Arab Republic',
    alpha2: 'SY',
    altName: 'Syrian Arab Republic (Syria)',
    shortName: 'Syria',
  },
  {
    name: 'Taiwan (Province of China)',
    alpha2: 'TW',
    altName: 'Taiwan, Republic of China',
    shortName: 'Taiwan',
  },
  {
    name: 'Tajikistan',
    alpha2: 'TJ',
  },
  {
    name: 'Tanzania, United Republic of',
    alpha2: 'TZ',
    shortName: 'Tanzania',
  },
  {
    name: 'Thailand',
    alpha2: 'TH',
  },
  {
    name: 'Timor-Leste',
    alpha2: 'TL',
    shortName: 'East Timor',
  },
  {
    name: 'Togo',
    alpha2: 'TG',
  },
  {
    name: 'Tokelau',
    alpha2: 'TK',
  },
  {
    name: 'Tonga',
    alpha2: 'TO',
  },
  {
    name: 'Trinidad and Tobago',
    alpha2: 'TT',
  },
  {
    name: 'Tunisia',
    alpha2: 'TN',
  },
  {
    name: 'Turkey',
    alpha2: 'TR',
  },
  {
    name: 'Turkmenistan',
    alpha2: 'TM',
  },
  {
    name: 'Turks and Caicos Islands (the)',
    alpha2: 'TC',
    altName: 'Turks and Caicos Islands',
  },
  {
    name: 'Tuvalu',
    alpha2: 'TV',
  },
  {
    name: 'Uganda',
    alpha2: 'UG',
  },
  {
    name: 'Ukraine',
    alpha2: 'UA',
  },
  {
    name: 'United Arab Emirates (the)',
    alpha2: 'AE',
    altName: 'United Arab Emirates',
  },
  {
    name: 'United Kingdom of Great Britain and Northern Ireland (the)',
    alpha2: 'GB',
    altName: 'United Kingdom',
  },
  {
    name: 'United States Minor Outlying Islands (the)',
    alpha2: 'UM',
    altName: 'US Minor Outlying Islands',
  },
  {
    name: 'United States of America (the)',
    alpha2: 'US',
    altName: 'United States of America',
    shortName: 'United States',
  },
  {
    name: 'Uruguay',
    alpha2: 'UY',
  },
  {
    name: 'Uzbekistan',
    alpha2: 'UZ',
  },
  {
    name: 'Vanuatu',
    alpha2: 'VU',
  },
  {
    name: 'Venezuela (Bolivarian Republic of)',
    alpha2: 'VE',
    altName: 'Venezuela (Bolivarian Republic)',
    shortName: 'Venezuela',
  },
  {
    name: 'Viet Nam',
    alpha2: 'VN',
    shortName: 'Vietnam',
  },
  {
    name: 'Virgin Islands (British)',
    alpha2: 'VG',
    altName: 'British Virgin Islands',
  },
  {
    name: 'Virgin Islands (U.S.)',
    alpha2: 'VI',
    altName: 'Virgin Islands, US',
    shortName: 'U.S. Virgin Islands',
  },
  {
    name: 'Wallis and Futuna',
    alpha2: 'WF',
    altName: 'Wallis and Futuna Islands',
  },
  {
    name: 'Western Sahara*',
    alpha2: 'EH',
    altName: 'Western Sahara',
  },
  {
    name: 'Yemen',
    alpha2: 'YE',
  },
  {
    name: 'Zambia',
    alpha2: 'ZM',
  },
  {
    name: 'Zimbabwe',
    alpha2: 'ZW',
  },
];
